"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_native_1 = require("react-native");
function ResponsiveStyle(webStyles, mobileStyles) {
    const web = react_native_1.StyleSheet.create(webStyles);
    const mobile = react_native_1.StyleSheet.create(mobileStyles ?? {});
    // Return a function that combines wraps web and mobile styles
    return (layout) => (style) => {
        if (layout.width < 768 && mobile.hasOwnProperty(style)) {
            return react_native_1.StyleSheet.compose(web[style], mobile[style]);
        }
        else
            return web[style];
    };
}
exports.default = ResponsiveStyle;
